import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import MainApi from './plugins/api/main';
import DialogPromise from './plugins/modals/DialogPromise.js';
import '@/assets/css/main.css';
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import storePlugin from '@/store/plugin'
import VueGtag from "vue-gtag";
import { VueMaskDirective } from 'v-mask'

Vue.config.productionTip = false;
Vue.config.devtools = true;

const requireComponent = require.context(
  // O caminho relativo da pasta de componentes
  './components',
  // Se deve ou não olhar subpastas
  false,
  // Expressão regular para localizar nomes de componentes base
  /Base[A-Z]\w+\.(vue|js)$/
)

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_KEY }
}, router);

requireComponent.keys().forEach(fileName => {
  // Pega a configuração do componente
  const componentConfig = requireComponent(fileName)

  // Obtém nome em PascalCase do componente
  const componentName = upperFirst(
    camelCase(
      // Obtém o nome do arquivo, independentemente da profundidade da pasta
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  // Registra o componente globalmente
  Vue.component(
    componentName,
    // Olha para as opções em `.default`, existentes
    // se o componente foi exportado com `export default`,
    // caso contrário usa o módulo raiz.
    componentConfig.default || componentConfig
  )
})

Vue.use(storePlugin);
Vue.use(MainApi);
Vue.use(DialogPromise);
Vue.directive('mask', VueMaskDirective);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
