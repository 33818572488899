import Vue from 'vue';
import moment from 'moment';

export default (function () {
    return {
        validaCpf(cpf) {
            if (typeof cpf !== "string") return false
            cpf = cpf.replace(/[\s.-]*/igm, '')
            if (
                !cpf ||
                cpf.length != 11 ||
                cpf == "00000000000" ||
                cpf == "11111111111" ||
                cpf == "22222222222" ||
                cpf == "33333333333" ||
                cpf == "44444444444" ||
                cpf == "55555555555" ||
                cpf == "66666666666" ||
                cpf == "77777777777" ||
                cpf == "88888888888" ||
                cpf == "99999999999"
            ) {
                return false
            }
            var soma = 0
            var resto
            for (let i = 1; i <= 9; i++)
                soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
            resto = (soma * 10) % 11
            if ((resto == 10) || (resto == 11)) resto = 0
            if (resto != parseInt(cpf.substring(9, 10))) return false
            soma = 0
            for (let i = 1; i <= 10; i++)
                soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
            resto = (soma * 10) % 11
            if ((resto == 10) || (resto == 11)) resto = 0
            if (resto != parseInt(cpf.substring(10, 11))) return false
            return true
        },
        loadImageFromDb: function (logo) {
            var bstr, n, u8arr;
            if (logo && !logo.content && logo.length) {
                logo = JSON.parse(logo);
            }
            if (logo && logo.content) {
                bstr = logo.content.split(",")[1];
                bstr = atob(bstr);
                n = bstr.length;
                u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File(
                    [u8arr],
                    logo.name,
                    { type: logo.type, size: n }
                );
            }
        },

        deleteAllCache: function () {
            // limpa o cache
            localStorage.clear();
            sessionStorage.clear();

            // limpa os cookies
            var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var eqPos = cookie.indexOf("=");
                var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
        },

        isObject: function (item) {
            return (item && typeof item === 'object' && !Array.isArray(item));
        },

        mergeDeep: function (target, ...sources) {
            let self = this;
            if (!sources.length) return target;
            const source = sources.shift();

            if (self.isObject(target) && self.isObject(source)) {
                for (const key in source) {
                    if (self.isObject(source[key])) {
                        if (!target[key]) Object.assign(target, { [key]: {} });
                        self.mergeDeep(target[key], source[key]);
                    } else {
                        Object.assign(target, { [key]: source[key] });
                    }
                }
            }

            return self.mergeDeep(target, ...sources);
        },

        parseImageToDb: async function (file) {
            let self = this;
            let ready = false;
            let result = '';

            const sleep = function (ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            }

            self.readAsDataURL = async function () {
                while (ready === false) {
                    await sleep(100);
                }
                return JSON.stringify({
                    name: file.name,
                    content:
                        "data:" +
                        file.type +
                        ";base64," +
                        btoa(result),
                    type: file.type
                });
            }

            const reader = new FileReader();
            reader.onloadend = function (evt) {
                result = evt.target.result;
                ready = true;
            };

            try {
                reader.readAsBinaryString(file);
            } catch (error) {
                return JSON.stringify({});
            }

            return self.readAsDataURL();
        },

        /**
         * clona um objeto ou array
         */
        jparse: function (data) {
            try {
                return JSON.parse(data);
            } catch (error) {
                return null;
            }
        },

        /**
         * retorna somente os números de uma string
         */
        somenteNumeros: function (str) {
            return str.replace(/[^0-9]/g, "");
        },

        /**
         * retorna somente os números de uma string
         */
        removeEspacos: function (str) {
            return str.replace(/\w/g, "");
        },

        /**
         * gera uma hash
         */
        uuid: function () {
            let s = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
            let r = Array(20).join().split(',').map(function () { return s.charAt(Math.floor(Math.random() * s.length)); }).join('');
            return r;
        },

        /**
         * converte um valor para o formato da moeda desejada
         */
        formatarValorMoeda: function (valor, moeda = 'BRL') {
            if (!(valor instanceof Number)) {
                valor = parseFloat(valor);
            }
            switch (moeda) {
                case 'BRL':
                    return valor.toFixed(2).replace(".", ",");
                default:
                    return valor.toFixed(2);
            }
        },

        /**
         * seta parametros de uma url
         */
        setParamsUrl(url, params) {
            url = new URL(url);
            for (let x in params) {
                url.searchParams.set(x, params[x])
            }
            return url;
        },

        queryStringAsJson(url) {
            let vars = url.split('&');
            let lreturn = {};
            for (let i = 0; i < vars.length; i++) {
                let pair = vars[i].split('=');
                lreturn[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
            }
            return lreturn;
        },

        clonar(data) {
            return JSON.parse(JSON.stringify(data));
        },

    };
})();


Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
    return formatter.format(value);
});

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
});