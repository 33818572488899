import axios from 'axios';
import router from '@/router';

export class MainApiClass {
    UrlServer = "";
    Vue = ''
    constructor(Vue) {
        this.Vue = Vue
        this.UrlServer = process.env.VUE_APP_API_MAIN_URL;
    }
    async get(endpoint, overlay = true) {
        if (overlay) {
            this.Vue.prototype.$DialogLoading(true);
        }
        let ret;
        try {
            ret = await axios.get(this.UrlServer + endpoint);
            if (ret?.data?.errors?.exception?.length) {
                this.Vue.prototype.$DialogInfo(ret.data.errors.exception[0]);
            } else if (ret?.data?.error) {
                this.Vue.prototype.$DialogInfo(ret.data.error);
            } else if (!ret?.data?.success && ret?.data?.message) {
                this.Vue.prototype.$DialogInfo(ret?.data?.message);
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                return router.push({path: '/login'});
            }
            if (error?.response?.status == 307 && error?.response?.data?.route) {
                if (error?.response?.data?.route == "pagamento") {
                    if (window.location.pathname.endsWith("/assinante/minha-conta")) {
                        return
                    }
                    if (error?.response?.data?.msg) {
                        await this.Vue.prototype.$DialogInfo(error?.response?.data?.msg);
                    }
                    return router.push({path: "/assinante/minha-conta"});
                }
            }
            if (error?.response?.data?.errors) {
                return alert('ERRO: ' + Object.values(error.response.data.errors)[0]);
            }
            return alert('ERRO: ' + (error?.response?.data?.errors?.exception?.[0] ?? error.message));
        } finally {
            if (overlay) {
                this.Vue.prototype.$DialogLoading(false);
            }
        }
        return ret?.data?.result || ret?.data;
    }
    async post(endpoint, data) {
        this.Vue.prototype.$DialogLoading(true);
        var ret;
        try {
            ret = await axios.post(this.UrlServer + endpoint, data);
            var htmlExtra = ret?.data?.html_extra;
            if (ret?.data?.errors?.exception?.length) {
                this.Vue.prototype.$DialogInfo(ret.data.errors.exception[0], htmlExtra);
            } else if (ret?.data?.error) {
                this.Vue.prototype.$DialogInfo(ret.data.error, htmlExtra);
            } else if (!ret?.data?.success && ret?.data?.message) {
                this.Vue.prototype.$DialogInfo(ret?.data?.message, htmlExtra);
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                return router.push({path: '/login'});
            }
            if (error?.response?.status == 307 && error?.response?.data?.route) {
                if (error?.response?.data?.route == "pagamento") {
                    if (error?.response?.data?.msg) {
                        await this.Vue.prototype.$DialogInfo(error?.response?.data?.msg);
                    }
                    return router.push({path: "/assinante/minha-conta"});
                }
            }
            if (error?.response?.data?.errors) {
                return this.Vue.prototype.$DialogInfo('ERRO: ' + Object.values(error.response.data.errors)[0]);
            }
            return this.Vue.prototype.$DialogInfo('ERRO: ' + (error?.response?.data?.errors?.exception?.[0] ?? error.message));
        } finally {
            this.Vue.prototype.$DialogLoading(false);
        }
        return ret?.data?.result || ret?.data;
    }
    async delete(endpoint) {
        this.Vue.prototype.$DialogLoading(true);
        let ret;
        try {
            ret = await axios.delete(this.UrlServer + endpoint);
            if (ret?.data?.errors?.exception?.length) {
                this.Vue.prototype.$DialogInfo(ret.data.errors.exception[0]);
            } else if (ret?.data?.error) {
                this.Vue.prototype.$DialogInfo(ret.data.error);
            } else if (!ret?.data?.success && ret?.data?.message) {
                this.Vue.prototype.$DialogInfo(ret?.data?.message);
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                return router.push({path: '/login'});
            }
            if (error?.response?.data?.errors) {
                return alert('ERRO: ' + Object.values(error.response.data.errors)[0]);
            }
            return alert('ERRO: ' + (error?.response?.data?.errors?.exception?.[0] ?? error.message));
        } finally {
            this.Vue.prototype.$DialogLoading(false);
        }
        return ret?.data?.result || ret?.data;
    }
}



export default {
    install (Vue) {
        Vue.prototype.$mainApi = new MainApiClass(Vue);
    }
}