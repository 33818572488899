<template>
  <v-dialog max-width="290" persistent v-model="show">
    <v-card>
      <v-card-title style="word-break: break-word;">{{ message }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="btnClicked(false)">Não</v-btn>
        <v-btn @click="btnClicked(true)">Sim</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DialogConfirm",
  data: () => ({
    show: false,
  }),
  props: ["message", "resolve"],
  created() {
    this.show = true;
  },
  methods: {
    btnClicked: function (val) {
      this.show = false;
      this.resolve(val);
    },
  },
};
</script>
<style scoped>
</style>