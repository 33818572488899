<template>
  <v-dialog v-model="show" persistent max-width="300">
    <v-card color="primary" dark>
      <v-card-text style="word-break: break-word;">
        {{ messageDefault }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DialogLoading",
  data: () => ({
    show: false,
    messageDefault: "Aguarde",
  }),
  props: ["message"],
  created() {
    this.show = true;
    if (this.message) {
      this.messageDefault = this.message;
    }
  }
};
</script>
<style scoped>
</style>