import DialogConfirm from '@/plugins/modals/DialogConfirm.vue';
import DialogLoading from '@/plugins/modals/DialogLoading.vue';
import DialogInfo from '@/plugins/modals/DialogInfo.vue';
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';

const _DialogConfirm = Vue.extend(DialogConfirm);
function _showDialogConfirm(message) {
    return new Promise((resolve) => {
        let dlog;
        new Promise(_resolve => {
            dlog = new _DialogConfirm({
                vuetify,
                propsData: {
                    message: message,
                    resolve: _resolve
                }
            });
            dlog.$mount();
        }).then(result => {
            setTimeout(() => dlog.$destroy, 300);
            resolve(result);
        });
    });
}

const _DialogLoading = Vue.extend(DialogLoading);
let _DialogLoadingState;
function _showDialogLoading(state, message) {
    if (!_DialogLoadingState) {
        _DialogLoadingState = new _DialogLoading({
            vuetify,
            propsData: {
                message: message
            }
        });
    }
    if (state) {
        _DialogLoadingState.$mount();
    } else {
        _DialogLoadingState.$destroy();
        _DialogLoadingState = undefined;
    }
}

const _DialogInfo = Vue.extend(DialogInfo);
function _showDialogInfo(message, htmlExtra) {
    return new Promise((resolve) => {
        let dlog;
        new Promise(_resolve => {
            dlog = new _DialogInfo({
                vuetify,
                propsData: {
                    message: message,
                    pHtmlExtra: htmlExtra,
                    resolve: _resolve
                }
            });
            dlog.$mount();
        }).then(result => {
            setTimeout(() => dlog.$destroy, 300);
            resolve(result);
        });
    });
}

const DialogPromise = {
    name: "DialogPromise",
    install(Vue) {
        Vue.prototype.$DialogConfirm = _showDialogConfirm;
        Vue.prototype.$DialogLoading = _showDialogLoading;
        Vue.prototype.$DialogInfo = _showDialogInfo;
    }
};
export default DialogPromise;